import Image from 'next/image'

export default function Home() {
  return (
    <div className="flex h-screen">
      <div className="m-auto">
        <a href="https://app.queso.dev">
          <Image src="/queso.png" alt="i love queso so much" width={500} height={500} priority />
        </a>
      </div>
      <div></div>
    </div>
  )
}
